<template>
	<div class="activity-detail">
		<w-navTab titleText="活动详情"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="activity-detail-banner"><img :src="require('@/assets/img/activity_detail_banner.jpg')" alt="" /></div>
		<div class="activity-detail-container">
			<div class="activity-detail-content">
				<div class="activity-detail-title">{{ activityDetail.title }}</div>
				<div class="activity-detail-box">
					<div class="activity-detail-attr">
						<div class="activity-detail-attr-left">
							<div class="activity-detail-attr-contact" @click="callPhone()">负责人电话</div>
							<div class="activity-detail-attr-status activity-detail-attr-status-blue">{{ onText(activityDetail.activity_status) }}</div>
						</div>
						<div class="activity-detail-attr-right">
							<div class="activity-detail-attr-approval">{{ activityDetail.approval }}</div>
							<div class="activity-detail-attr-views">{{ activityDetail.views }}</div>
						</div>
					</div>
					<div class="activity-detail-body">
						<div class="activity-detail-cell">
							<div class="activity-detail-cell-title activity-detail-cell-dept">组织单位</div>
							<div class="activity-detail-cell-content">{{ activityDetail.department_name }}</div>
						</div>
						<div class="activity-detail-cell">
							<div class="activity-detail-cell-title activity-detail-cell-date">活动日期</div>
							<div class="activity-detail-cell-content">{{ activityDetail.begin_date }} - {{ activityDetail.finish_date }}</div>
						</div>
						<div class="activity-detail-cell">
							<div class="activity-detail-cell-title activity-detail-cell-time">打卡时间</div>
							<div class="activity-detail-cell-content">{{ activityDetail.begin_time }} - {{ activityDetail.finish_time }}</div>
						</div>
						<div class="activity-detail-cell">
							<div class="activity-detail-cell-title activity-detail-cell-over">已报人数</div>
							<div class="activity-detail-cell-content">{{ activityDetail.register_number }}人</div>
						</div>
						<div class="activity-detail-cell">
							<div class="activity-detail-cell-title activity-detail-cell-numb">需求人数</div>
							<div class="activity-detail-cell-content">{{ activityDetail.people_number }}人</div>
						</div>
						<div class="activity-detail-cell">
							<div class="activity-detail-cell-title activity-detail-cell-addr">活动地址</div>
							<div class="activity-detail-cell-content">{{ activityDetail.address }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="activity-detail-intro" v-if="activityDetail.peoples != ''">
				<div class="activity-detail-card-title">已报名人员</div>
				<div class="activity-detail-card-user">
					<div class="card-user-item" v-for="item in activityDetail.peoples">
						<div class="user-item-picture">
							<img :src="item.avatar" alt="" /> 
							<div class="user-item-picture-spot-green" v-if="item.register_status == 1"></div>
							<div class="user-item-picture-spot-red" v-if="item.register_status == 0"></div>
							<div class="user-item-picture-spot-gray" v-if="item.register_status == 2"></div>
							<!-- <div :class="item.register_status == 1 ? 'user-item-picture-spot-green' : 'user-item-picture-spot-red'"></div> -->
						</div>
						<div class="urer-item-text">{{ item.name }}</div>
					</div>
				</div>
			</div>
			<div class="activity-detail-intro">
				<div class="activity-detail-card-title">活动简介</div>
				<div class="activity-detail-card-content">{{ activityDetail.intro }}</div>
			</div>
		</div>
		<div class="activity-detail-footer">
			<div class="activity-detail-footer-share" @click="onshare()"><img :src="require('@/assets/img/icon13.png')" /></div>
			<div class="activity-detail-footer-button">
				<van-button v-if="activityDetail.state == 1" round type="info" color="#3377FF" @click="signIn">{{ stateText(activityDetail.state) }}</van-button>
				<van-button class="button-text-color" v-else round type="info" color="#DDDDDD" disabled>{{ stateText(activityDetail.state) }}</van-button>
			</div>
			<div class="activity-detail-footer-approval" @click="onPraise" v-if="!praiseShow"><img :src="require('@/assets/img/icon14-2.png')" /></div>
			<div class="activity-detail-footer-approval" @click="onPraise" v-if="praiseShow"><img :src="require('@/assets/img/icon14.png')" /></div>
		</div>
		<Share :loading="isShowShare" @Touch="share()"></Share>
	</div>
</template>

<script>
import VantVendor from '@/vendor/vant';
import ActivityApi from '@/api/activity';
import LoadingPage from '@/components/LoadingPage';
import Share from '@/components/Share';
import Vant from '@/vendor/vant';
import News from '@/api/news';
import Config from '@/vendor/config';
import District from '@/vendor/district';

export default {
	inject: ['className', 'districtType', 'themeColor'],
	name: 'ActivityDetail',
	data() {
		return {
			activityId: '',
			activityDetail: '',
			loadingPage: true,
			isShowShare: false,
			praiseShow: true
		};
	},
	created() {
		this.activityId = this.$route.params.activityId;
		this.getActivityDetail();
		let params = {
			page_id: this.activityId,
			type_id: 3
		};
		News.isPraise(params)
			.then(result => {
				this.praiseShow = result.data.is_praise;
			})
			.catch(error => {});
	},
	methods: {
		callPhone() {
			window.location.href = 'tel://' + this.activityDetail.contact_telephone;
		},
		onPraise() {
			let params = {
				type_id: 3,
				page_id: this.activityId
			};
			ActivityApi.praise(params)
				.then(result => {
					this.praiseShow = true;
					VantVendor.Toast('点赞成功');
					this.activityDetail.approval++;
				})
				.catch(error => {
					VantVendor.Toast(error.msg);
				});
		},
		onText(status) {
			let text = { 1: '报名中', 2: '活动中', 3: '已结束' };
			return text[status];
		},
		stateText(status) {
			let text = { 1: '报名活动', 2: '该活动已结束', 3: '您已报名该活动', 4: '报名人数已满', 5: '活动报名队伍不符合' };
			return text[status];
		},
		getActivityDetail() {
			let params = {
				activity_id: this.activityId
			};

			ActivityApi.getDetail(params)
				.then(result => {
					this.activityDetail = result.data;
					this.loadingPage = false;

					wx.onMenuShareTimeline({
						title: this.activityDetail.title + '-' + document.title,
						link: window.Location.href,
						imgUrl: Config.shareLogoUrl,
						success: () => {
							this.isShowShare = false;
							Vant.Toast.success('分享成功');
						},
						error: () => {
							console.log(12);
						}
					});

					wx.onMenuShareAppMessage({
						title: this.activityDetail.title + '-' + document.title,
						link: window.Location.href,
						imgUrl: Config.shareLogoUrl,
						success: () => {
							this.isShowShare = false;
							Vant.Toast.success('分享成功');
						},
						error: () => {
							console.log(12);
						}
					});
				})
				.catch(error => {
					VantVendor.Toast(error.msg);
				});
		},
		signIn() {
			let params = {
				activity_id: this.activityId
			};
			
			ActivityApi.SignIn(params)
				.then(result => {
					VantVendor.Dialog.alert({
						title: '恭喜你, 活动报名成功',
						message: '若您想取消报名，请前往个人中心——已报名活动内取消，谢谢'
					}).then(() => {
						this.getActivityDetail();
					});
				})
				.catch(error => {
					VantVendor.Toast(error.msg);
				});
		},
		// 分享
		onshare() {
			this.isShowShare = true;
		},
		share() {
			this.isShowShare = false;
		}
	},
	components: {
		LoadingPage,
		Share
	}
};
</script>

<style lang="less" scoped>
@import '~@/assets/less/style.less';

.activity-detail {
	padding-bottom: 78px;

	.activity-detail-banner {
		img {
			width: 100%;
			height: 200px;
			object-fit: cover;
			display: block;
		}
	}

	.activity-detail-container {
		margin-top: -15px;

		.activity-detail-content {
			padding: 10px;
			padding-bottom: 0;
			background-color: #f2f2f2;
			position: relative;
			border-radius: 15px 15px 0px 0px;
			margin-bottom: 10px;

			.activity-detail-title {
				font-size: 18px;
				font-weight: bold;
				position: relative;
				padding-left: 10px;
				line-height: 27px;
				margin-bottom: 10px;

				&:after {
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 3px;
					height: 20px;
					border-radius: 10px;
					background-color: #3377ff;
				}
			}

			.activity-detail-box {
				background-color: #fff;
				padding: 10px;
				border-radius: 6px;

				.activity-detail-attr {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-bottom: 15px;
					.border-bottom();

					.activity-detail-attr-left {
						display: flex;
						align-items: center;

						.activity-detail-attr-contact {
							font-size: 15px;
							color: #6abb1b;
							font-weight: bold;
							margin-right: 20px;
							padding-left: 23px;
							background-image: url('../../assets/img/activity_detail_telephone.png');
							background-position: left center;
							background-repeat: no-repeat;
							background-size: 18px;
						}

						.activity-detail-attr-status {
							font-size: 14px;
							padding: 1px 5px;
							margin-right: 8px;
							border-radius: 2px;

							&.activity-detail-attr-status-blue {
								background-color: rgba(51, 119, 255, 0.1);
								color: #3377ff;
							}

							&.activity-detail-attr-status-red {
								background-color: rgba(255, 105, 105, 0.1);
								color: #ff6969;
							}
						}
					}

					.activity-detail-attr-right {
						display: flex;
						align-items: center;

						.activity-detail-attr-approval,
						.activity-detail-attr-views {
							padding-left: 18px;
							font-size: 14px;
							color: #666;
							background-position: left center;
							background-repeat: no-repeat;
							background-size: 16px;
						}

						.activity-detail-attr-approval {
							background-image: url('../../assets/img/icon15.png');
							margin-right: 10px;
						}

						.activity-detail-attr-views {
							background-image: url('../../assets/img/icon16.png');
						}
					}
				}

				.activity-detail-body {
					padding-top: 10px;

					.activity-detail-cell {
						display: flex;
						justify-content: space-between;
						line-height: 26px;
						padding-top: 8px;

						&:first-child {
							padding-top: 0;
						}
					}

					.activity-detail-cell-title {
						color: #666;
						font-size: 16px;
						padding-left: 21px;
						background-position: left 4px;
						background-repeat: no-repeat;
						background-size: 16px;
						box-sizing: border-box;
						min-width: 100px;

						&.activity-detail-cell-dept {
							background-image: url('../../assets/img/icon17.png');
						}

						&.activity-detail-cell-date {
							background-image: url('../../assets/img/icon18.png');
						}

						&.activity-detail-cell-time {
							background-image: url('../../assets/img/icon19.png');
						}

						&.activity-detail-cell-over {
							background-image: url('../../assets/img/icon20.png');
						}

						&.activity-detail-cell-numb {
							background-image: url('../../assets/img/icon21.png');
						}

						&.activity-detail-cell-addr {
							background-image: url('../../assets/img/icon22.png');
						}
					}

					.activity-detail-cell-content {
						color: #333;
						font-size: 16px;
					}
				}
			}
		}

		.activity-detail-intro {
			background-color: #fff;
			padding: 10px;
			margin: 0 10px;
			box-sizing: border-box;
			border-radius: 6px;
			margin-bottom: 10px;

			.activity-detail-card-title {
				font-weight: bold;
				font-size: 16px;
				line-height: 24px;
				padding-bottom: 10px;
				.border-bottom();
			}
			.activity-detail-card-content {
				font-size: 16px;
				line-height: 26px;
				color: #666;
				padding: 10px 0;
			}
			.activity-detail-card-user {
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.card-user-item {
					display: flex;
					align-items: center;
					margin-top: 8px;
					margin-right: 8px;

					.user-item-picture {
						width: 20px;
						min-width: 20px;
						height: 20px;
						position: relative;
						img {
							width: 100%;
							height: 100%;
							border-radius: 50%;
							object-fit: cover;
						}
						.user-item-picture-spot-red{
							width: 8px;
							height: 8px;
							background: #F94438;
							border: 0.5px solid #fff;
							border-radius: 50%;
							position: absolute;
							top: -3px;
							right: -3px;
						}
						.user-item-picture-spot-green{
							width: 8px;
							height: 8px;
							background: #6edc38;
							border: 0.5px solid #fff;
							border-radius: 50%;
							position: absolute;
							top: -3px;
							right: -3px;
						}
						.user-item-picture-spot-gray{
							width: 8px;
							height: 8px;
							background: #bbb;
							border: 0.5px solid #fff;
							border-radius: 50%;
							position: absolute;
							top: -3px;
							right: -3px;
						}
					}
					.urer-item-text {
						padding-left: 2px;
						font-size: 16px;
						line-height: 20px;
						color: #777;
						min-width: 53px;
					}
				}
			}
		}
	}

	.activity-detail-footer {
		padding: 10px;
		box-sizing: border-box;
		width: 100%;
		height: 68px;
		background-color: #fff;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		position: fixed;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		img {
			width: 48px;
			height: 48px;
		}

		.activity-detail-footer-button {
			flex: 1;
			padding: 0 13px;

			.button-text-color {
				color: #666 !important;
			}

			.van-button {
				width: 100%;
				font-weight: bold;
				font-size: 20px;
			}
		}
	}
}
</style>
